import type { QueryKey } from '@tanstack/react-query';
import type { GetPliantTransactionsParams } from './api';

export const PLIANT_TRANSACTIONS_KEY = (
  params?: Partial<GetPliantTransactionsParams['params']>
): QueryKey => {
  const base = 'pliant-transactions';
  if (params) {
    return [base, params];
  }
  return [base];
};
