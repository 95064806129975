import { PaginatedQuery, RangeDateFilter } from '@pflegenavi/shared/utils';
import {
  IsArray,
  IsEnum,
  IsIn,
  IsNotEmpty,
  IsOptional,
  IsUUID,
  ValidateNested,
} from 'class-validator';
import { type SortOrder } from './index';
import { ReceiptStatusValues } from './ReceiptStatusValues';

export enum PaginatedReceiptQuerySortBy {
  residentName = 'residentName',
  submissionDate = 'submissionDate',
  receiptDate = 'receiptDate',
  amount = 'amount',
}

export enum PaginatedReceiptQueryAdditionalFields {
  creator = 'creator',
}

export class PaginatedReceiptQueryDto extends PaginatedQuery {
  @IsNotEmpty()
  @IsUUID(4)
  nursingHomeId!: string;

  @IsOptional()
  @IsNotEmpty()
  @IsUUID(4)
  cashTransactionGroupId?: string;

  @IsOptional()
  @IsEnum(ReceiptStatusValues, { each: true })
  @IsArray()
  status?: ReceiptStatusValues[];

  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  residentIds?: string[];

  @IsOptional()
  @ValidateNested()
  submissionDateRange?: RangeDateFilter<Date>;

  @IsOptional()
  @ValidateNested()
  receiptDateRange?: RangeDateFilter<Date>;

  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  receiptTypeIds?: string[];

  @IsOptional()
  @IsUUID(4, { each: true })
  @IsArray()
  serviceProviderIds?: string[];

  @IsOptional()
  @IsEnum(PaginatedReceiptQuerySortBy)
  sortBy?: PaginatedReceiptQuerySortBy;

  @IsOptional()
  @IsIn(['asc', 'desc'])
  sortOrder?: SortOrder;

  @IsOptional()
  @IsArray()
  @IsEnum(PaginatedReceiptQueryAdditionalFields, { each: true })
  additionalFields?: PaginatedReceiptQueryAdditionalFields[];

  @IsOptional()
  @IsUUID(4, { each: true })
  createdByEmployeeIds?: string[];
}
