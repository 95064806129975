import type { QueryClient, QueryKey } from '@tanstack/react-query';

import type { ResidentState } from '@pflegenavi/shared/api';
import { RESIDENTS_PHOENIX_KEY } from '../resident-phoenix/queryKeys';

export const RESIDENTS_KEY = (
  nursingHomeId: string | undefined,
  states?: { [key in ResidentState]?: boolean },
  additionalFields?: {
    [key: string]: any;
  }
): QueryKey => {
  return [
    'residents-list',
    nursingHomeId,
    {
      ...(states ? { states } : {}),
      ...(additionalFields ? { additionalFields } : {}),
    },
  ];
};

export const RESIDENTS_KEY2 = (
  nursingHomeId: string | undefined,
  additionalFields?: {
    [key: string]: any;
  }
): QueryKey => {
  return ['residents2-list', nursingHomeId, { ...additionalFields }];
};

export const invalidateResidentsKeys = (
  client: QueryClient,
  nursingHomeId: string | undefined
): Promise<[void, void, void]> => {
  return Promise.all([
    client.invalidateQueries({ queryKey: RESIDENTS_KEY(nursingHomeId) }),
    client.invalidateQueries({ queryKey: RESIDENTS_KEY2(nursingHomeId) }),
    client.invalidateQueries({
      queryKey: RESIDENTS_PHOENIX_KEY(nursingHomeId ?? ''),
    }),
  ]);
};

export const invalidatePendingReceiptCount = (
  queryClient: QueryClient
): Promise<void> => {
  return queryClient.invalidateQueries({
    predicate: (query) => {
      const pendingReceiptCount =
        Array.isArray(query.queryKey) &&
        query.queryKey[2] !== undefined &&
        typeof query.queryKey[2] === 'object' &&
        'pendingReceiptCount' in query.queryKey[2] &&
        query.queryKey[2]?.['pendingReceiptCount'];
      return (
        query.queryKey[0] === RESIDENTS_KEY('')[0] &&
        pendingReceiptCount === true
      );
    },
  });
};

export const getResidentKey = (id: string): QueryKey => ['residents', id];
export const getShowResidentKey = (residentId: string): QueryKey => [
  'show-resident',
  residentId,
];
export const UPDATE_RESIDENT_KEY: QueryKey = ['update-resident'];
export const EXIT_RESIDENT_KEY: QueryKey = ['exit-resident'];
export const ARCHIVE_RESIDENT_KEY: QueryKey = ['archive-resident'];

export const GET_RECURRING_ITEMS: QueryKey = ['get-recurring-items'];
export const GET_RECURRING_ITEMS_KEY = (
  nursingHomeId: string | undefined
): QueryKey => [GET_RECURRING_ITEMS, nursingHomeId];

export const GET_COUNTRIES_KEY: QueryKey = ['get-countries'];
export const GET_TAX_CODES_BY_COUNTRY = (
  countryId: string | undefined
): QueryKey => ['get-tax-codes-by-country', countryId];

export const GET_RECURRING_ITEM_RESIDENTS_KEY = (
  recurringItemId: string
): QueryKey => [`get-recurring-item-residents-${recurringItemId}`];

export const GET_RECURRING_ITEMS_BY_RESIDENT_ID_KEY = (
  residentId: string
): QueryKey => [`get-recurring-items-${residentId}`];
export const REMOVE_RECURRING_ITEM_KEY: QueryKey = ['remove-recurring-charge'];
export const ADD_RESIDENT_RECURRING_ITEM_KEY: QueryKey = [
  'add-resident-recurring-item',
];
export const UPDATE_RESIDENT_START_DATE_KEY: QueryKey = [
  'recurring-item-update-resident-start-date',
];
export const getResidentConfigurationKey = (id: string): QueryKey => [
  `get-resident-configuration-${id}`,
];
export const UPDATE_RESIDENT_CONFIGURATION_KEY: QueryKey = [
  'update-resident-configuration',
];

export const UPDATE_RESIDENT_CASH_PAYER_STATUS_KEY: QueryKey = [
  'update-resident-cash-payer-status',
];

export const NURSING_HOME_KEY = (
  nursingHomeId: string | undefined
): QueryKey => ['nursing-home', nursingHomeId];
export const PATCH_NURSING_HOME_PAYMENT_SETTINGS_KEY = (
  nursingHomeId?: string
): QueryKey => ['patch-nursing-home-payment-settings', nursingHomeId];
export const RESIDENT_SETTLEMENT_KEY = (id: string): QueryKey => [
  `resident-settlement-${id}`,
];

export const UPDATE_NURSING_HOME_KEY: QueryKey = ['update-nursing-home'];

export const PROCEED_TO_AWAITING_SETTLEMENT_KEY = (
  residentId: string
): QueryKey => [`proceed-to-awaiting-settlement-${residentId}`];
export const GO_BACK_TO_EXITED_KEY = (residentId: string): QueryKey => [
  `go-back-to-exited-${residentId}`,
];

export const RESIDENT_SERVICE_PROVIDERS_LIST_KEY = (id: string): QueryKey => [
  `resident-service-providers-list-${id}`,
];
export const PUT_RESIDENT_SERVICE_PROVIDERS_LIST_KEY: QueryKey = [
  `put-resident-service-providers-list`,
];

/**
 * Invalidate all resident lists manually. The RESIDENTS_KEY and RESIDENTS_KEY2 key generators don't
 * properly work when the nursing home is undefined, since it will create ['residents-list', undefined] and that matches no key.
 * RESIDENTS_KEY and RESIDENTS_KEY2 was not modified to avoid unnecessary invalidations, as currently at startup
 * nursing_home_id is undefined and we don't want to trigger invalidations at startup.
 */
export const invalidateAllResidentLists = (
  client: QueryClient
): Promise<[void, void, void]> => {
  return Promise.all([
    client.invalidateQueries({ queryKey: ['residents-list'] }),
    client.invalidateQueries({ queryKey: ['residents2-list'] }),
    client.invalidateQueries({ queryKey: ['residents-phoenix'] }),
  ]);
};

export const invalidateResident = async (
  client: QueryClient,
  id: string,
  nursingHomeId: string | undefined
): Promise<void> => {
  await Promise.all([
    client.invalidateQueries({ queryKey: getResidentKey(id) }),
    client.invalidateQueries({ queryKey: getShowResidentKey(id) }),
    // Also invalidate resident lists
    ...(nursingHomeId
      ? [invalidateResidentsKeys(client, nursingHomeId)]
      : [invalidateAllResidentLists(client)]),
  ]);
};
