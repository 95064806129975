import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import type { QueryKey } from '@tanstack/react-query';
import {
  useMutation,
  type UseMutationResult,
  useQueryClient,
} from '@tanstack/react-query';

import { RESIDENTS_KEY } from '../resident/queryKeys';
import { createContext } from 'react';
import type { IReceiptPhoenixApi } from './api';
import { ReceiptPhoenixApi } from './api';
import { invalidateAllResidentBalance } from '../reporting/queryKeys';
import {
  getReceiptKey,
  RECEIPTS_AND_RECEIPT_BATCHES_KEY,
  RECEIPTS_KEY,
} from '../transaction/queryKeys';
import { ALL_SHOW_RESIDENT_KEY } from '../resident-phoenix/queryKeys';
import { getFinanceKey } from '../reporting-phoenix';

const ApiContext = createContext<IReceiptPhoenixApi | undefined>(undefined);
const {
  useApi: useReceiptsPhoenixApi,
  ApiProvider: ReceiptsPhoenixApiProvider,
} = makeApiProvider({
  name: 'ReceiptsPhoenix',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) =>
    new ReceiptPhoenixApi(tenantId, auth, apiUrl),
});

export type { ReceiptPhoenixApi, IReceiptPhoenixApi };
export {
  useReceiptsPhoenixApi,
  ReceiptsPhoenixApiProvider,
  ApiContext as ReceiptsPhoenixApiContext,
};

const SUBMIT_RECEIPTS_KEY: QueryKey = ['submit-receipts'];
const REFUND_RECEIPTS_KEY: QueryKey = ['refund-receipts'];

export const useSubmitReceiptsPhoenix = (): UseMutationResult<
  { data: Array<{ id: string; status?: number; message?: string }> },
  unknown,
  {
    body: { receipt_ids: string[] };
    nursingHomeId?: string;
  }
> => {
  const api = useReceiptsPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    { data: Array<{ id: string; status?: number; message?: string }> },
    unknown,
    {
      body: { receipt_ids: string[] };
      nursingHomeId?: string;
    },
    unknown
  >({
    mutationKey: SUBMIT_RECEIPTS_KEY,
    mutationFn: (params) => api.submit(params),
    onSettled: async (_data, _error, params) => {
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: RECEIPTS_KEY }),
        queryClient.invalidateQueries({
          queryKey: getFinanceKey(params.nursingHomeId),
        }),
        invalidateAllResidentBalance(queryClient, params.nursingHomeId),
        queryClient.invalidateQueries({ queryKey: ALL_SHOW_RESIDENT_KEY() }),
        queryClient.invalidateQueries({
          queryKey: RESIDENTS_KEY(params.nursingHomeId),
        }),
        queryClient.invalidateQueries({
          queryKey: RECEIPTS_AND_RECEIPT_BATCHES_KEY(params.nursingHomeId),
        }),
      ]);
    },
  });
};

export const useCancelReceiptPhoenix = (
  nursingHomeId?: string
): UseMutationResult<
  void,
  unknown,
  {
    body: { data: { note: string } };
    params: { receipt_id: string };
  }
> => {
  const api = useReceiptsPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      body: { data: { note: string } };
      params: { receipt_id: string };
    },
    unknown
  >({
    mutationKey: REFUND_RECEIPTS_KEY,
    mutationFn: (params) => api.cancel(params),
    onSettled: async (_data, _error, params) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: getReceiptKey(params.params.receipt_id),
        }),
        queryClient.invalidateQueries({
          queryKey: RESIDENTS_KEY(nursingHomeId),
        }),
        invalidateAllResidentBalance(queryClient, nursingHomeId),
      ]);
    },
  });
};
