import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext } from 'react';
import type {
  GetCountriesPhoenixResponse,
  IFamilyMemberPhoenixApi,
  ResidentFamilyMemberResponse,
  SetupMangoPayDirectDebitBody,
  SetupMangoPayDirectDebitResidentRequest,
  SetupMangoPayDirectDebitResponse,
} from './api';
import { FamilyMemberPhoenixApi } from './api';
import type {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useQueryClient } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { PAYMENTS_INFO_KEY } from '../payment-phoenix';

const ApiContext = createContext<IFamilyMemberPhoenixApi | undefined>(
  undefined
);
const {
  useApi: useFamilyMemberPhoenixApi,
  ApiProvider: FamilyMemberPhoenixApiProvider,
} = makeApiProvider({
  name: 'FamilyMemberPhoenix',
  ApiContext,
  newApi: (_tenantId, auth, apiUrl) => new FamilyMemberPhoenixApi(auth, apiUrl),
});

export {
  useFamilyMemberPhoenixApi,
  FamilyMemberPhoenixApiProvider,
  SetupMangoPayDirectDebitBody,
  SetupMangoPayDirectDebitResponse,
};
export type { IFamilyMemberPhoenixApi, Country } from './api';

const GET_COUNTRIES_PHOENIX_KEY = (tenant: string | undefined) => [
  'GET_PHOENIX_COUNTRIES',
  tenant,
];

const SETUP_MANGOPAY_DIRECT_DEBIT_KEY: QueryKey = [
  'SETUP_MANGOPAY_DIRECT_DEBIT',
];
const GET_MANGOPAY_DIRECT_DEBIT_KEY = ['GET_MANGOPAY_DIRECT_DEBIT'];
const GET_MANGOPAY_DIRECT_DEBIT_RESIDENT_KEY = (residentId: string) => [
  'GET_MANGOPAY_DIRECT_DEBIT',
  residentId,
];

const GET_FAMILY_MEMBER_RESIDENT_KEY = (resident_id: string) => [
  'GET_FAMILY_MEMBER_RESIDENT',
  resident_id,
];
export const useGetGetFamilyMemberCountries = ({
  tenant,
  options,
}: {
  tenant?: string;
  options?: Omit<
    UseQueryOptions<GetCountriesPhoenixResponse, unknown>,
    'queryFn' | 'queryKey'
  >;
}): UseQueryResult<GetCountriesPhoenixResponse, unknown> => {
  return useApiQuery(
    useFamilyMemberPhoenixApi,
    GET_COUNTRIES_PHOENIX_KEY(tenant),
    (api) => {
      if (!tenant) {
        throw new Error('tenant is required');
      }
      return api.getCountries({
        params: {
          tenant,
        },
      });
    },
    {
      ...options,
      enabled: !!tenant,
    }
  );
};

export const useGetMangoPayDirectDebit = ({
  paymentProcessorMode,
}: {
  paymentProcessorMode: 'live' | 'test';
}): UseQueryResult<SetupMangoPayDirectDebitResponse, unknown> => {
  return useApiQuery(
    useFamilyMemberPhoenixApi,
    GET_MANGOPAY_DIRECT_DEBIT_KEY,
    (api) => {
      return api.getMangoPayDirectDebit({
        params: {
          paymentProcessorMode,
        },
      });
    }
  );
};

export const useSetupMangoPayDirectDebit = ({
  paymentProcessorMode,
}: {
  paymentProcessorMode: 'live' | 'test';
}): UseMutationResult<
  SetupMangoPayDirectDebitResponse,
  unknown,
  SetupMangoPayDirectDebitBody
> => {
  const api = useFamilyMemberPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    SetupMangoPayDirectDebitResponse,
    unknown,
    SetupMangoPayDirectDebitBody
  >({
    mutationKey: SETUP_MANGOPAY_DIRECT_DEBIT_KEY,
    mutationFn: (data) => {
      return api.setupMangoPayDirectDebit({
        params: {
          paymentProcessorMode,
        },
        body: data,
      });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: GET_MANGOPAY_DIRECT_DEBIT_KEY,
        }),
      ]);
    },
  });
  return result;
};

export const useGetMangoPayDirectDebitResident = ({
  paymentProcessorMode,
  tenant,
  resident_id,
}: {
  paymentProcessorMode: 'live' | 'test';
  tenant: string;
  resident_id: string;
}): UseQueryResult<SetupMangoPayDirectDebitResponse, unknown> => {
  return useApiQuery(
    useFamilyMemberPhoenixApi,
    GET_MANGOPAY_DIRECT_DEBIT_RESIDENT_KEY(resident_id),
    (api) => {
      return api.getMangoPayDirectDebitResident({
        params: {
          paymentProcessorMode,
          resident_id,
          tenant,
        },
      });
    }
  );
};

export const useSetupMangoPayDirectDebitResident = (): UseMutationResult<
  SetupMangoPayDirectDebitResponse,
  unknown,
  SetupMangoPayDirectDebitResidentRequest
> => {
  const api = useFamilyMemberPhoenixApi();
  const queryClient = useQueryClient();
  const result = useMutation<
    SetupMangoPayDirectDebitResponse,
    unknown,
    SetupMangoPayDirectDebitResidentRequest
  >({
    mutationKey: SETUP_MANGOPAY_DIRECT_DEBIT_KEY,
    mutationFn: (data) => {
      return api.setupMangoPayDirectDebitResident(data);
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: GET_MANGOPAY_DIRECT_DEBIT_KEY,
        }),
      ]);
    },
  });
  return result;
};

export const useGetFamilyMemberResident = ({
  tenant,
  resident_id,
}: {
  tenant: string;
  resident_id: string;
}): UseQueryResult<ResidentFamilyMemberResponse, unknown> => {
  return useApiQuery(
    useFamilyMemberPhoenixApi,
    GET_FAMILY_MEMBER_RESIDENT_KEY(resident_id),
    (api) => {
      return api.getFamilyMemberResident({
        params: {
          tenant,
          resident_id,
        },
      });
    }
  );
};

export const useUpdateFamilyMemberResident = ({
  tenant,
  resident_id,
}: {
  tenant: string;
  resident_id: string;
}): UseMutationResult<
  ResidentFamilyMemberResponse,
  unknown,
  { source_of_mandate: 'resident' | 'family_member' }
> => {
  const api = useFamilyMemberPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    ResidentFamilyMemberResponse,
    unknown,
    { source_of_mandate: 'resident' | 'family_member' }
  >({
    mutationKey: SETUP_MANGOPAY_DIRECT_DEBIT_KEY,
    mutationFn: (data) => {
      return api.updateFamilyMemberResident({
        params: {
          tenant,
          resident_id,
        },
        body: data,
      });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: GET_FAMILY_MEMBER_RESIDENT_KEY(resident_id),
        }),
      ]);
    },
  });
};

export const useCancelFamilyMemberMandate = (): UseMutationResult<
  void,
  unknown,
  { paymentProcessorMode: 'live' | 'test' }
> => {
  const api = useFamilyMemberPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<void, unknown, { paymentProcessorMode: 'live' | 'test' }>({
    mutationKey: ['CANCEL_FAMILY_MEMBER_MANDATE'],
    mutationFn: (params) => {
      return api.cancelFamilyMemberMandate({ params });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: GET_MANGOPAY_DIRECT_DEBIT_KEY,
        }),
      ]);
    },
  });
};

export const useCancelResidentMandate = (): UseMutationResult<
  void,
  unknown,
  { paymentProcessorMode: 'live' | 'test'; resident_id: string; tenant: string }
> => {
  const api = useFamilyMemberPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      paymentProcessorMode: 'live' | 'test';
      resident_id: string;
      tenant: string;
    }
  >({
    mutationKey: ['CANCEL_RESIDENT_MANDATE'],
    mutationFn: (params) => {
      return api.cancelResidentMandate({ params });
    },
    onSuccess: async () => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: GET_MANGOPAY_DIRECT_DEBIT_KEY,
        }),
      ]);
    },
  });
};

export const useDoManualPayin = (): UseMutationResult<
  void,
  unknown,
  { params: { resident_id: string; tenant: string }; body: { amount: number } }
> => {
  const api = useFamilyMemberPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    void,
    unknown,
    {
      params: { resident_id: string; tenant: string };
      body: { amount: number };
    }
  >({
    mutationKey: ['DO_MANUAL_PAYIN'],
    mutationFn: (params) => {
      return api.manualPayin(params);
    },
    onSuccess: async (_, params) => {
      await Promise.all([
        queryClient.invalidateQueries({
          queryKey: GET_MANGOPAY_DIRECT_DEBIT_KEY,
        }),
        queryClient.invalidateQueries({
          queryKey: PAYMENTS_INFO_KEY(params.params.resident_id),
        }),
      ]);
    },
  });
};
