import {
  makeApiProvider,
  useApiQuery,
  usePhoenixApiInfiniteQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext } from 'react';
import type {
  InfiniteData,
  UseInfiniteQueryResult,
  UseQueryResult,
} from '@tanstack/react-query';
import type {
  GetPliantTransactionsParams,
  GetPliantTransactionsResponse,
  IPliantPhoenixApi,
  PliantTransaction,
} from './api';
import { PliantPhoenixApi, PliantStatus } from './api';
import { PLIANT_TRANSACTIONS_KEY } from './queryKeys';

export type {
  PliantTransaction,
  GetPliantTransactionsParams,
  GetPliantTransactionsResponse,
};

const ApiContext = createContext<IPliantPhoenixApi | undefined>(undefined);

const { useApi: usePliantPhoenixApi, ApiProvider: PliantPhoenixApiProvider } =
  makeApiProvider({
    name: 'PliantPhoenixApi',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new PliantPhoenixApi(tenantId, auth, apiUrl),
  });

export {
  usePliantPhoenixApi,
  PliantPhoenixApiProvider,
  ApiContext as PliantPhoenixApiContext,
  PliantStatus,
};

export function usePliantTransactions(
  params: GetPliantTransactionsParams
): UseQueryResult<GetPliantTransactionsResponse, unknown> {
  return useApiQuery(
    usePliantPhoenixApi,
    PLIANT_TRANSACTIONS_KEY(params.params),
    (api) => api.getPliantTransactions(params),
    {
      enabled: params.params.legal_entity_id !== '',
    }
  );
}

export function usePliantTransactionsInfiniteQuery({
  legal_entity_id,
  ...otherParams
}: Omit<GetPliantTransactionsParams['params'], 'page' | 'page_size'> & {
  page_size?: number;
}): UseInfiniteQueryResult<
  InfiniteData<GetPliantTransactionsResponse>,
  unknown
> {
  const pageSize = otherParams.page_size ?? 20;

  return usePhoenixApiInfiniteQuery(
    usePliantPhoenixApi,
    PLIANT_TRANSACTIONS_KEY({
      legal_entity_id,
      page: 1,
      page_size: pageSize,
      ...otherParams,
    }),
    async (api, pageParam) => {
      if (legal_entity_id === '') {
        throw new Error('legal_entity_id is empty');
      }

      const result = await api.getPliantTransactions({
        params: {
          legal_entity_id,
          page: pageParam as number,
          page_size: pageSize,
          ...otherParams,
        },
      });

      return result;
    },
    { enabled: legal_entity_id !== '' }
  );
}
