import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type { Coin } from '@pflegenavi/shared/api';
import { addMissingFactorsAndSort } from '../cash-management';

export interface CreateCashListTransactionGroupParams {
  body: {
    data: {
      coins?: Coin[];
      notes?: string;
      bank_account_amount?: number;
      type: 'resident deposit';
      resident_id: string;
    };
  };
  params: {
    cashListId: string;
  };
}

export interface GetCashListTransactionGroupParams {
  params: {
    page: number;
    pageSize: number;
    cashListIds?: string[];
    pliantPaymentEmployeeId?: string;
    mismatch?: boolean;
    groupTypes?: GroupType[];
  };
}

type GroupType =
  | 'ReceiptWithdrawal'
  | 'ResidentDeposit'
  | 'ResidentWithdrawal'
  | 'Transfer'
  | 'Adjustment'
  | 'Deposit';

export interface CashListTransactionGroupSingle {
  id: string;
  group_type: GroupType;
  employee_id: string;
  mismatch_confirmation_timestamp: string;
  cash_list_transactions: Array<{
    pliant_transaction: {
      authorized_at?: Date;
      confirmed_at?: Date;
      created_at?: Date;
      billing_amount: number;
    };
  }>;
  cash_changed: number;
  transaction_amount: number;
  amount_linked: number;
  linking_difference: number;
  update_date: Date;
}

export interface CashListTransactionGroup {
  id: string;
  group_type: GroupType;
  employee_id: string;
  mismatch_confirmation_timestamp: string;
  pliant_transaction_amount: number;
  cash_changed: number;
  transaction_amount: number;
  amount_linked: number;
  linking_difference: number;
  update_date: Date;
}

export interface GetCashListTransactionGroupResponse {
  cash_transaction_group_list: CashListTransactionGroup[];
  meta: {
    current_page: number;
    previous_page: number;
    next_page: number;
    page_size: number;
    total_count: number;
    total_pages: number;
    has_next_page: boolean;
    has_previous_page: boolean;
  };
}

export interface CreateCashListTransactionGroupFromPliantParams {
  body: {
    nursing_home_id: string;
    pliant_transaction_id: string;
  };
}

export interface ICashManagementPhoenixApi extends Api {
  createCashListTransactionGroup(
    dataIn: CreateCashListTransactionGroupParams
  ): Promise<{
    id: string;
  }>;

  createFromPliant(
    dataIn: CreateCashListTransactionGroupFromPliantParams
  ): Promise<{ cash_list_transaction_group: CashListTransactionGroupSingle }>;

  getCashListTransactionGroup(
    dataIn: GetCashListTransactionGroupParams
  ): Promise<GetCashListTransactionGroupResponse>;
}

export class CashManagementPhoenixApi implements ICashManagementPhoenixApi {
  public createCashListTransactionGroup: ICashManagementPhoenixApi['createCashListTransactionGroup'];
  public getCashListTransactionGroup: ICashManagementPhoenixApi['getCashListTransactionGroup'];
  public createFromPliant: ICashManagementPhoenixApi['createFromPliant'];
  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    const baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.createCashListTransactionGroup = modify({
      authContext,
      method: 'POST',
      transformBody: ({ data }) => {
        if (data.coins) {
          return {
            data: {
              ...data,
              coins: addMissingFactorsAndSort(data.coins).map(
                (coin) => coin.amount
              ),
            },
          };
        } else {
          return { data };
        }
      },
      url: ({ cashListId }) =>
        `${baseUrl}/cash-list/${cashListId}/cash-transaction-group`,
    });

    this.createFromPliant = modify({
      authContext,
      method: 'POST',
      url: () => `${baseUrl}/cash-list/pliant-cash-transaction`,
    });

    this.getCashListTransactionGroup = get({
      authContext,
      url: (params) => {
        const query = new URLSearchParams();

        query.append('page', params.page.toString());
        query.append('page_size', params.pageSize.toString());

        let i = 0;
        if (params.cashListIds) {
          query.append(`filters[${i}][field]`, 'cash_list_id');
          query.append(`filters[${i}][op]`, 'in');
          for (const cashListId of params.cashListIds) {
            query.append(`filters[${i}][value][]`, cashListId);
          }
          i++;
        }

        if (params.groupTypes) {
          query.append(`filters[${i}][field]`, 'group_type');
          query.append(`filters[${i}][op]`, 'in');
          for (const groupType of params.groupTypes) {
            query.append(`filters[${i}][value][]`, groupType);
          }
          i++;
        }

        if (params.pliantPaymentEmployeeId) {
          query.append(`filters[${i}][field]`, 'pliant_payment_employee_ids');
          query.append(`filters[${i}][op]`, '==');
          query.append(
            `filters[${i}][value][]`,
            params.pliantPaymentEmployeeId
          );
          i++;
        }

        if (params.mismatch === true) {
          query.append(
            `filters[${i}][field]`,
            'mismatch_confirmation_timestamp'
          );
          query.append(`filters[${i}][op]`, 'empty');
          query.append(`filters[${i}][value]`, 'true');
          i++;
          query.append(`filters[${i}][field]`, 'linking_difference');
          query.append(`filters[${i}][op]`, '!=');
          query.append(`filters[${i}][value]`, '0');
          i++;
        }

        return `${baseUrl}/cash-list/cash-transaction-group-list?${query.toString()}`;
      },
    });
  }
}
