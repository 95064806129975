import {
  makeApiProvider,
  useApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import { createContext, useCallback } from 'react';
import type {
  CashListTransactionGroupSingle,
  CreateCashListTransactionGroupFromPliantParams,
  CreateCashListTransactionGroupParams,
  GetCashListTransactionGroupParams,
  GetCashListTransactionGroupResponse,
  ICashManagementPhoenixApi,
} from './api';
import { CashManagementPhoenixApi } from './api';
import type {
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import {
  CASH_LIST_CONFIGURATION_KEY,
  CASH_TRANSACTION_GROUP_LIST_KEY,
} from '../cash-management/queryKeys';
import { PAYMENTS_INFO_KEY } from '../payment-phoenix';
import { CASH_TRANSACTION_GROUP_LIST_PHOENIX_KEY } from './queryKeys';
import { PLIANT_TRANSACTIONS_KEY } from '../pliant-phoenix/queryKeys';

export type {
  ICashManagementPhoenixApi,
  CashListTransactionGroup,
  GetCashListTransactionGroupResponse,
} from './api';

const ApiContext = createContext<ICashManagementPhoenixApi | undefined>(
  undefined
);
const {
  useApi: useCashManagementPhoenixApi,
  ApiProvider: CashManagementPhoenixApiProvider,
} = makeApiProvider({
  name: 'CashManagementPhoenixApi',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) =>
    new CashManagementPhoenixApi(tenantId, auth, apiUrl),
});

export {
  useCashManagementPhoenixApi,
  CashManagementPhoenixApiProvider,
  ApiContext as CashManagementPhoenixApiContext,
};

export function useCashListTransactionGroupCreate(
  nursingHomeId: string | undefined
): UseMutationResult<
  { id: string },
  unknown,
  CreateCashListTransactionGroupParams
> {
  const api = useCashManagementPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    { id: string },
    unknown,
    CreateCashListTransactionGroupParams
  >({
    mutationKey: ['cash-transaction-group-add-transaction'],
    mutationFn: (data) => api.createCashListTransactionGroup(data),
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: CASH_TRANSACTION_GROUP_LIST_KEY,
        }),
        queryClient.invalidateQueries({
          queryKey: CASH_TRANSACTION_GROUP_LIST_PHOENIX_KEY(),
        }),
        queryClient.invalidateQueries({
          queryKey: PAYMENTS_INFO_KEY(undefined),
        }),
        queryClient.invalidateQueries({
          queryKey: CASH_LIST_CONFIGURATION_KEY(nursingHomeId),
        }),
      ]);
    },
  });
}

export function useCashListTransactionGroupFromPliantCreate(): UseMutationResult<
  { cash_list_transaction_group: CashListTransactionGroupSingle },
  unknown,
  CreateCashListTransactionGroupFromPliantParams
> {
  const api = useCashManagementPhoenixApi();
  const queryClient = useQueryClient();
  return useMutation<
    { cash_list_transaction_group: CashListTransactionGroupSingle },
    unknown,
    CreateCashListTransactionGroupFromPliantParams
  >({
    mutationKey: ['cash-transaction-group-from-pliant-create'],
    mutationFn: async (data) => {
      const result = await api.createFromPliant(data);
      return {
        ...result,
        cash_list_transaction_group: {
          ...result.cash_list_transaction_group,
          cash_list_transactions:
            result.cash_list_transaction_group.cash_list_transactions.map(
              (transaction) => ({
                ...transaction,
                pliant_transaction: {
                  ...transaction.pliant_transaction,
                  authorized_at: transaction.pliant_transaction.authorized_at
                    ? new Date(transaction.pliant_transaction.authorized_at)
                    : undefined,
                  confirmed_at: transaction.pliant_transaction.confirmed_at
                    ? new Date(transaction.pliant_transaction.confirmed_at)
                    : undefined,
                  created_at: transaction.pliant_transaction.created_at
                    ? new Date(transaction.pliant_transaction.created_at)
                    : undefined,
                },
              })
            ),
        },
      };
    },
    onSuccess: async () => {
      return await Promise.all([
        queryClient.invalidateQueries({
          queryKey: CASH_TRANSACTION_GROUP_LIST_KEY,
        }),
        queryClient.invalidateQueries({
          queryKey: PLIANT_TRANSACTIONS_KEY(),
        }),
      ]);
    },
  });
}

export function useCashListTransactionGroupPhoenix(
  params: GetCashListTransactionGroupParams['params'],
  options?: Omit<
    UseQueryOptions<GetCashListTransactionGroupResponse, unknown>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<GetCashListTransactionGroupResponse, unknown> {
  const select = useCallback((data: GetCashListTransactionGroupResponse) => {
    const cashTransactionGroupList = data.cash_transaction_group_list.map(
      (group) => ({
        ...group,
        update_date: new Date(group.update_date),
      })
    );

    return {
      ...data,
      cash_transaction_group_list: cashTransactionGroupList,
    };
  }, []);

  return useApiQuery(
    useCashManagementPhoenixApi,
    CASH_TRANSACTION_GROUP_LIST_PHOENIX_KEY(params),
    (api) => {
      return api.getCashListTransactionGroup({
        params,
      });
    },
    {
      select,
      ...options,
    }
  );
}
