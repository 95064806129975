import { makeApiProvider } from '@pflegenavi/shared-frontend/platform';
import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import { createContext } from 'react';

import type {
  INotificationsApi,
  RegisterTokenParams,
  UnregisterTokenParams,
} from './api';
import { NotificationsApi } from './api';
import { REGISTER_TOKEN_KEY, UNREGISTER_TOKEN_KEY } from './queryKeys';

export type {
  INotificationsApi,
  RegisterTokenParams,
  UnregisterTokenParams,
} from './api';

const ApiContext = createContext<INotificationsApi | undefined>(undefined);
const { useApi: useNotificationsApi, ApiProvider: NotificationsApiProvider } =
  makeApiProvider({
    name: 'Notifications',
    ApiContext,
    newApi: (tenantId, auth, apiUrl) =>
      new NotificationsApi(tenantId, auth, apiUrl),
  });

export {
  NotificationsApi,
  ApiContext as NotificationsApiContext,
  NotificationsApiProvider,
  useNotificationsApi,
};

export const useRegisterToken = (): UseMutationResult<
  void,
  unknown,
  RegisterTokenParams
> => {
  const api = useNotificationsApi();

  return useMutation({
    mutationKey: REGISTER_TOKEN_KEY,
    mutationFn: (params: RegisterTokenParams) => {
      return api.registerToken(params);
    },
  });
};

export const useUnregisterToken = (): UseMutationResult<
  void,
  unknown,
  UnregisterTokenParams
> => {
  const api = useNotificationsApi();

  return useMutation({
    mutationKey: UNREGISTER_TOKEN_KEY,
    mutationFn: (params: UnregisterTokenParams) => {
      return api.unregisterToken(params);
    },
  });
};
