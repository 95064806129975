import type { QueryKey } from '@tanstack/react-query';
import type { GetCashListTransactionGroupParams } from './api';

export const CASH_TRANSACTION_GROUP_LIST_PHOENIX_KEY = (
  params?: GetCashListTransactionGroupParams['params']
): QueryKey => {
  const base = 'cash-transaction-group-list';
  if (params) {
    return [base, params];
  }
  return [base];
};

export const CASH_TRANSACTION_GROUP_FROM_PLIANT_PHOENIX_KEY = (): QueryKey => {
  const base = 'cash-transaction-group-from-pliant';
  return [base];
};
