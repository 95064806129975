import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import type { Api } from '@pflegenavi/shared-frontend/platform';
import {
  get,
  getPhoenixApiTenantUrl,
  modify,
} from '@pflegenavi/shared-frontend/platform';
import type {
  FamilyMemberPhoenix,
  FamilyMemberResidentRelationship,
  Gender,
  GetAllResidentsResult,
  ImportFamilyMemberPhoenix,
  ImportFamilyMemberResponsePhoenix,
  ResidentState,
  ShowResidentDto,
} from '@pflegenavi/shared/api';

export interface GetAllResidentsPhoenixParams {
  page?: number;
  pageSize?: number;
  states?: ResidentState[];
  nursingHomeId: string;
}

export interface CreateResidentResult {
  data: any;
}

export type InitialBalance =
  | {
      cash_list_id: string;
      notes?: string;
      coins: number[];
    }
  | {
      cash_list_id: string;
      notes?: string;
      bank_amount: number;
    }
  | {
      notes?: string;
      amount: number;
    };

export interface UpdateResidentFamilyMemberDto {
  user_id: string;
  first_name: string;
  last_name: string;
  notes?: string;
  phone?: string;
  address?: string;
  zip_code?: string;
  city?: string;
  country?: string;
  relationship_to_resident: FamilyMemberResidentRelationship;
}

export interface CreateResidentParams {
  body: {
    nursing_home_id: string;
    data: {
      entry_date: Date;
      firstname: string;
      lastname: string;
      gender: Gender;
      birthdate?: Date;
      resident_accounting_id?: string;
      initial_balance?: InitialBalance;
      service_providers?: Array<{
        global_service_provider_id: string;
      }>;
      family_member?: {
        first_name: string;
        last_name: string;
        email: string;
        notes?: string;
        phone?: string;
        address?: string;
        zip_code?: string;
        city?: string;
        country?: string;
        relationship_to_resident: FamilyMemberResidentRelationship | undefined;
      };
    };
  };
}
export interface IResidentPhoenixApi extends Api {
  getAllResidents(dataIn: {
    params: GetAllResidentsPhoenixParams;
  }): Promise<GetAllResidentsResult>;

  createResident(dataIn: CreateResidentParams): Promise<CreateResidentResult>;
  showResident(dataIn: {
    params: { residentId: string };
  }): Promise<ShowResidentDto>;
  showResidentForFamilyMember(dataIn: {
    params: { tenant: string; residentId: string };
  }): Promise<ShowResidentDto>;
  resendFamilyMemberWelcomeEmail(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<void>;

  importFamilyMembers(dataIn: {
    body: ImportFamilyMemberPhoenix;
  }): Promise<ImportFamilyMemberResponsePhoenix>;

  addNewFamilyMember(dataIn: {
    params: {
      residentId: string;
    };
    body: {
      data: FamilyMemberPhoenix;
    };
  }): Promise<{
    status: 'Created';
  }>;

  deleteFamilyMemberForGivenResidentPhoenix(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
  }): Promise<{
    success: boolean;
  }>;

  updateFamilyMemberForGivenResidentPhoenix(dataIn: {
    params: {
      residentId: string;
      familyMemberId: string;
    };
    body: {
      data: UpdateResidentFamilyMemberDto;
    };
  }): Promise<{ data: UpdateResidentFamilyMemberDto }>;
}

export class ResidentPhoenixApi implements IResidentPhoenixApi {
  baseUrl: string;

  getAllResidents: IResidentPhoenixApi['getAllResidents'];
  createResident: IResidentPhoenixApi['createResident'];
  showResident: IResidentPhoenixApi['showResident'];
  showResidentForFamilyMember: IResidentPhoenixApi['showResidentForFamilyMember'];
  resendFamilyMemberWelcomeEmail: IResidentPhoenixApi['resendFamilyMemberWelcomeEmail'];
  public importFamilyMembers: IResidentPhoenixApi['importFamilyMembers'];
  public addNewFamilyMember: IResidentPhoenixApi['addNewFamilyMember'];
  public deleteFamilyMemberForGivenResidentPhoenix: IResidentPhoenixApi['deleteFamilyMemberForGivenResidentPhoenix'];
  public updateFamilyMemberForGivenResidentPhoenix: IResidentPhoenixApi['updateFamilyMemberForGivenResidentPhoenix'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);
    const familyMemberBaseUrl = getPhoenixApiTenantUrl('family-member', apiUrl);

    this.getAllResidents = get({
      authContext,
      url: (params) => {
        const query = new URLSearchParams();

        query.append('nursing_home_id', params.nursingHomeId);

        if (params.page) {
          query.append('page', params.page.toString());
        }

        if (params.pageSize) {
          query.append('page_size', params.pageSize.toString());
        }

        let i = 0;
        if (params.states) {
          query.append(`filters[${i}][field]`, 'state');
          query.append(`filters[${i}][op]`, 'in');
          for (const state of params.states) {
            query.append(`filters[${i}][value][]`, state);
          }
          i++;
        }

        return `${this.baseUrl}/resident?${query.toString()}`;
      },
    });

    this.createResident = modify({
      authContext,
      url: `${this.baseUrl}/resident`,
      method: 'POST',
    });

    this.showResident = get({
      authContext,
      url: (params) => `${this.baseUrl}/resident/${params.residentId}`,
    });

    this.showResidentForFamilyMember = get({
      authContext,
      url: (params) =>
        `${familyMemberBaseUrl}/${params.tenant}/${params.residentId}/resident-info`,
    });

    this.resendFamilyMemberWelcomeEmail = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}/resend_welcome`,
      method: 'POST',
    });

    this.importFamilyMembers = modify<
      ImportFamilyMemberPhoenix,
      ImportFamilyMemberResponsePhoenix
    >({
      authContext,
      url: `${this.baseUrl}/resident/family-member/import`,
      method: 'POST',
    });

    this.addNewFamilyMember = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member`,
      method: 'POST',
    });

    this.deleteFamilyMemberForGivenResidentPhoenix = modify<
      undefined,
      {
        success: boolean;
      },
      {
        success: boolean;
      },
      {
        residentId: string;
        familyMemberId: string;
      }
    >({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}`,
      method: 'DELETE',
    });

    this.updateFamilyMemberForGivenResidentPhoenix = modify({
      authContext,
      url: (params) =>
        `${this.baseUrl}/resident/${params.residentId}/family-member/${params.familyMemberId}`,
      method: 'PUT',
    });
  }
}
