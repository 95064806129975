import { createContext, useContext } from 'react';
import type {
  CashListStorageType,
  Coin,
  CashListSegment,
} from '@pflegenavi/shared/api';
import { CashListAccountType } from '@pflegenavi/shared/api';

export interface CashList {
  id: string;
  name: string;
  type: CashListAccountType;
  storageType: CashListStorageType;
  totalInCent: number;
  coins: Coin[];
  currency: string;
  bankAccountAmountInCent: number;
  segment: CashListSegment;
}

export interface ICashListConfigurationContext {
  selectedCashListId: string | undefined;
  setSelectedCashListId: (cashListId: string) => void;
  cashLists: CashList[];
  payoutTargetCashListId: string | undefined;
  payinTargetCashListId: string | undefined;
  serviceProviderPaymentTargetCashListId: string | undefined;
  pliantReferenceCashListId: string | undefined;
}

export function getOperationalCashList(
  cashListConfiguration: ICashListConfigurationContext
): CashList {
  const result = cashListConfiguration.cashLists.find(
    (cashList) => cashList.type === CashListAccountType.Operational
  );
  if (!result) {
    throw new Error('Could not find operational cash list');
  }
  return result;
}

export function getReserveCashList(
  cashListConfiguration: ICashListConfigurationContext
): CashList {
  const result = cashListConfiguration.cashLists.find(
    (cashList) => cashList.type === CashListAccountType.Reserve
  );
  if (!result) {
    throw new Error('Could not find reserve cash list');
  }
  return result;
}

export const CashListConfigurationContext =
  createContext<ICashListConfigurationContext>({
    selectedCashListId: 'provider-not-initialized',
    setSelectedCashListId: () => {
      return;
    },
    cashLists: [],
    payoutTargetCashListId: undefined,
    payinTargetCashListId: undefined,
    serviceProviderPaymentTargetCashListId: undefined,
    pliantReferenceCashListId: undefined,
  });

export const useCashListConfiguration = (): ICashListConfigurationContext => {
  return useContext(CashListConfigurationContext);
};
