import { createContext, useCallback } from 'react';
import {
  makeApiProvider,
  useApiQuery,
  usePrefetchApiQuery,
} from '@pflegenavi/shared-frontend/platform';
import type { IServiceProviderApi } from './api';
import { ServiceProviderApi } from './api';
import type {
  GetServiceProviderResidentsResultDto,
  PostServiceProviderDto,
  PutServiceProviderResidentsDto,
  ServiceProviderInviteStatus,
  ServiceProviderListDto,
  ServiceProvidersPaginatedQueryParams,
  ServiceProvidersQuery,
  ServiceProviderStatus,
  ServiceProviderStatusCombined,
  ServiceProviderWithStatementDescriptorDto,
} from '@pflegenavi/shared/api';
import type {
  QueryKey,
  UseMutationResult,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type {
  PaginatedResultSet,
  PaginatedResultSubset,
} from '@pflegenavi/shared/utils';

const ApiContext = createContext<IServiceProviderApi | undefined>(undefined);
const {
  useApi: useServiceProviderApi,
  ApiProvider: ServiceProviderApiProvider,
} = makeApiProvider({
  name: 'ServiceProvider',
  ApiContext,
  newApi: (tenantId, auth, apiUrl) =>
    new ServiceProviderApi(tenantId, auth, apiUrl),
});

export type { ServiceProviderApi, IServiceProviderApi };
export {
  useServiceProviderApi,
  ServiceProviderApiProvider,
  ApiContext as ServiceProviderApiContext,
};

const SERVICE_PROVIDER_COMMON_KEY: QueryKey = ['service-provider'];
const SERVICE_PROVIDER_RESIDENTS_COMMON_KEY: QueryKey = [
  'service-provider-residents',
];

const SERVICE_PROVIDER_KEY = (
  nursingHomeId: string,
  serviceProviderId: string
) => {
  return [SERVICE_PROVIDER_COMMON_KEY, nursingHomeId, serviceProviderId];
};

const SERVICE_PROVIDER_PHOENIX_KEY = (serviceProviderId: string) => {
  return [`${SERVICE_PROVIDER_COMMON_KEY}-PHOENIX`, serviceProviderId];
};

const SERVICE_PROVIDER_RESIDENTS_KEY = (
  nursingHomeId: string,
  serviceProviderId: string
) => {
  return [
    SERVICE_PROVIDER_RESIDENTS_COMMON_KEY,
    nursingHomeId,
    serviceProviderId,
  ];
};

const SET_SERVICE_PROVIDER_RESIDENTS_KEY = ['set-service-provider-residents'];

const UPDATE_SERVICE_PROVIDER_STATEMENT_DESCRIPTOR_KEY: QueryKey = [
  'update-service-provider-statement-descriptor',
];

const SERVICE_PROVIDERS_INVALIDATED_KEY = (
  nursingHomeId: string | undefined
) => {
  return [`serviceProviders`, nursingHomeId];
};

const SERVICE_PROVIDERS_KEY_PAGINATED = (
  nursingHomeId: string | undefined,
  receiptType?: string,
  searchTerms?: string,
  page?: number,
  pageSize?: number,
  combinedStatus?: ServiceProviderStatusCombined,
  status?: ServiceProviderStatus[] | ServiceProviderStatusCombined,
  inviteStatus?: ServiceProviderInviteStatus[]
) => {
  return [
    `serviceProviders`,
    nursingHomeId,
    {
      receiptType,
      searchTerms,
      page,
      pageSize,
      combinedStatus,
      status,
      inviteStatus,
    },
  ];
};

const SERVICE_PROVIDERS_KEY = (
  nursingHomeId: string | undefined,
  residentId?: string,
  receiptType?: string,
  searchTerms?: string
) => {
  return [
    `serviceProviders`,
    nursingHomeId,
    {
      residentId,
      receiptType,
      searchTerms,
    },
  ];
};

const ADD_SERVICE_PROVIDER_KEY: QueryKey = ['add-service-provider'];
const INVITE_SERVICE_PROVIDER_KEY: QueryKey = ['invite-service-provider'];
const CANCEL_SERVICE_PROVIDER_INVITE_KEY: QueryKey = [
  'cancel-service-provider-invitate',
];

export const usePrefetchServiceProviders = (
  nursingHomeId: string | undefined
): void => {
  const getServiceProviders = useCallback(
    (api: IServiceProviderApi) => {
      if (!nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getServiceProviders({ nursingHomeId });
    },
    [nursingHomeId]
  );

  return usePrefetchApiQuery(
    useServiceProviderApi,
    SERVICE_PROVIDERS_KEY(nursingHomeId),
    getServiceProviders,
    nursingHomeId !== undefined
  );
};

export const useTransactionServiceProviders = (
  opts:
    | ServiceProvidersQuery
    | (undefined & {
        nursingHomeId: string | undefined;
      }),
  options?: Omit<UseQueryOptions<string[], unknown>, 'queryFn' | 'queryKey'>
): UseQueryResult<string[], unknown> => {
  return useApiQuery(
    useServiceProviderApi,
    SERVICE_PROVIDERS_KEY(
      opts?.nursingHomeId,
      opts?.residentId,
      opts?.receiptType,
      opts?.searchTerms
    ),
    (api) => {
      if (!opts.nursingHomeId) {
        throw new Error('nursingHomeId is required');
      }
      return api.getServiceProviders({
        ...opts,
        nursingHomeId: opts.nursingHomeId,
      });
    },
    {
      enabled: opts.nursingHomeId !== undefined,
      ...options,
    }
  );
};

export const useTransactionServiceProvidersPaginated = (
  opts: Omit<ServiceProvidersPaginatedQueryParams, 'nursingHomeId'> & {
    nursingHomeId: string | undefined;
  },
  options?: Omit<
    UseQueryOptions<PaginatedResultSet<ServiceProviderListDto>, unknown>,
    'queryFn' | 'queryKey'
  >
): UseQueryResult<PaginatedResultSubset<ServiceProviderListDto>, unknown> => {
  const select = useCallback(
    (
      data: PaginatedResultSet<ServiceProviderListDto>
    ): PaginatedResultSubset<ServiceProviderListDto> => {
      return {
        ...data,
        data: data.data.map((serviceProvider) => ({
          ...serviceProvider,
          lastUsed: serviceProvider.lastUsed
            ? new Date(serviceProvider.lastUsed)
            : serviceProvider.lastUsed,
        })),
      };
    },
    []
  );

  return useApiQuery(
    useServiceProviderApi,
    SERVICE_PROVIDERS_KEY_PAGINATED(
      opts?.nursingHomeId,
      opts?.receiptTypeId,
      opts?.searchTerm,
      opts?.page,
      opts?.pageSize,
      opts?.combinedStatus,
      opts?.status,
      opts?.inviteStatus
    ),
    (api) => {
      if (!opts.nursingHomeId || !api) {
        throw new Error('nursingHomeId is required');
      }
      return api.getServiceProvidersPaginated({
        ...opts,
        nursingHomeId: opts.nursingHomeId,
      });
    },
    {
      enabled: opts.nursingHomeId !== undefined,
      select: select,
      ...options,
    }
  );
};
export const useAddServiceProvider = ({
  nursingHomeId,
}: {
  nursingHomeId: string | undefined;
}): UseMutationResult<
  {
    id: string;
  },
  Error,
  {
    data: PostServiceProviderDto;
  }
> => {
  const api = useServiceProviderApi();
  const queryClient = useQueryClient();
  return useMutation<
    {
      id: string;
    },
    Error,
    {
      data: PostServiceProviderDto;
    }
  >({
    mutationKey: ADD_SERVICE_PROVIDER_KEY,
    mutationFn: ({ data }) =>
      api.addServiceProvider({
        data,
        nursingHomeId,
      }),
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: SERVICE_PROVIDERS_INVALIDATED_KEY(nursingHomeId),
      });
    },
  });
};

export const useInviteServiceProviderPhoenix = ({
  serviceProviderId,
  nursingHomeId,
}: {
  serviceProviderId: string;
  nursingHomeId?: string;
}): UseMutationResult<
  {
    success: boolean;
  },
  Error,
  {
    email: string;
  }
> => {
  const api = useServiceProviderApi();
  const queryClient = useQueryClient();

  if (!nursingHomeId) {
    throw new Error('nursingHomeId is required');
  }
  return useMutation<
    {
      success: boolean;
    },
    Error,
    {
      email: string;
    }
  >({
    mutationKey: INVITE_SERVICE_PROVIDER_KEY,
    mutationFn: (data) =>
      api.inviteServiceProviderPhoenix({
        serviceProviderId,
        email: data.email,
      }),
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: SERVICE_PROVIDER_KEY(nursingHomeId, serviceProviderId),
      });
    },
  });
};

export const useCancelServiceProviderInvite = ({
  serviceProviderId,
  nursingHomeId,
}: {
  serviceProviderId: string;
  nursingHomeId?: string;
}): UseMutationResult<
  {
    success: boolean;
  },
  Error,
  void
> => {
  const api = useServiceProviderApi();
  const queryClient = useQueryClient();

  if (!nursingHomeId) {
    throw new Error('nursingHomeId is required');
  }
  return useMutation<
    {
      success: boolean;
    },
    Error,
    void
  >({
    mutationKey: CANCEL_SERVICE_PROVIDER_INVITE_KEY,
    mutationFn: () =>
      api.cancelServiceProviderInvite({
        serviceProviderId,
        nursingHomeId,
      }),
    onSettled: async () => {
      return await queryClient.invalidateQueries({
        queryKey: SERVICE_PROVIDER_KEY(nursingHomeId, serviceProviderId),
      });
    },
  });
};

export const useGetServiceProviderResidents = ({
  serviceProviderId,
  nursingHomeId,
}: {
  serviceProviderId: string | undefined;
  nursingHomeId: string | undefined;
}): UseQueryResult<GetServiceProviderResidentsResultDto[], unknown> => {
  return useApiQuery(
    useServiceProviderApi,
    SERVICE_PROVIDER_RESIDENTS_KEY(
      nursingHomeId ?? '',
      serviceProviderId ?? ''
    ),
    (api) => {
      if (!nursingHomeId || !serviceProviderId) {
        throw new Error('nursingHomeId and service provider are required');
      }
      return api.getServiceProviderResidents(serviceProviderId, nursingHomeId);
    },
    {
      enabled: nursingHomeId !== undefined && serviceProviderId !== undefined,
    }
  );
};

export const useSetServiceProviderResidents = ({
  serviceProviderId,
  nursingHomeId,
}: {
  serviceProviderId?: string;
  nursingHomeId?: string;
}): UseMutationResult<
  {
    success: boolean;
  },
  Error,
  {
    data: PutServiceProviderResidentsDto;
  }
> => {
  const api = useServiceProviderApi();
  const queryClient = useQueryClient();

  if (!nursingHomeId || !serviceProviderId) {
    throw new Error('nursingHomeId and serviceProviderId are required ');
  }
  return useMutation<
    {
      success: boolean;
    },
    Error,
    {
      data: PutServiceProviderResidentsDto;
    }
  >({
    mutationKey: SET_SERVICE_PROVIDER_RESIDENTS_KEY,
    mutationFn: ({ data }) =>
      api.setServiceProviderResidents({
        serviceProviderId,
        nursingHomeId,
        data,
      }),
    onSuccess: async () => {
      return await queryClient.invalidateQueries({
        queryKey: SERVICE_PROVIDER_RESIDENTS_KEY(
          nursingHomeId,
          serviceProviderId
        ),
      });
    },
  });
};

export const useGetServiceProviderPhoenix = ({
  serviceProviderId,
}: {
  serviceProviderId: string | undefined;
}): UseQueryResult<
  {
    data: ServiceProviderWithStatementDescriptorDto;
  },
  unknown
> => {
  return useApiQuery(
    useServiceProviderApi,
    SERVICE_PROVIDER_PHOENIX_KEY(serviceProviderId ?? ''),
    (api) => {
      if (!serviceProviderId) {
        throw new Error('service provider is required');
      }
      return api.getServiceProviderPhoenix(serviceProviderId);
    },
    {
      enabled: serviceProviderId !== undefined,
    }
  );
};

export const useUpdateServiceProviderStatementDescriptor = ({
  serviceProviderId,
}: {
  serviceProviderId?: string;
}): UseMutationResult<
  {
    success: boolean;
  },
  Error,
  {
    data: {
      statement_descriptor: string;
    };
  }
> => {
  const api = useServiceProviderApi();
  const queryClient = useQueryClient();

  if (!serviceProviderId) {
    throw new Error('ServiceProviderId are required ');
  }
  return useMutation<
    {
      success: boolean;
    },
    Error,
    {
      data: {
        statement_descriptor: string;
      };
    }
  >({
    mutationKey: UPDATE_SERVICE_PROVIDER_STATEMENT_DESCRIPTOR_KEY,
    mutationFn: ({ data }) =>
      api.updateServiceProviderStatementDescriptor({
        serviceProviderId,
        data,
      }),
    onSuccess: async () => {
      return await queryClient.invalidateQueries({
        queryKey: SERVICE_PROVIDER_PHOENIX_KEY(serviceProviderId),
      });
    },
  });
};
