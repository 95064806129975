export enum ReceiptStatusValues {
  SUBMITTED = 'Submitted',
  PENDING = 'Pending',
  DRAFT = 'Draft',
  INCOMPLETE = 'Incomplete',
}

export enum ReceiptState {
  Submitted = 'Submitted',
  Pending = 'Pending',
  Draft = 'Draft',
  Incomplete = 'Incomplete',
}

export const receiptStatusValuesFromString = (
  data: string
): ReceiptStatusValues => {
  // TODO: We can add validation here. But if we throw validation errors, we might break the current application
  return data as ReceiptStatusValues;
};
