import type { AuthenticationContext } from '@pflegenavi/frontend/authentication';
import type { Tenant } from '@pflegenavi/frontend/tenant';
import {
  get,
  getPhoenixApiTenantUrl,
  type Api,
} from '@pflegenavi/shared-frontend/platform';

export interface TransactionCountry {
  id: string;
  name: string;
  iso_code_3: string;
  iso_code: string;
  numeric_code: number;
}

export interface CashListTransactionGroupList {
  id: string;
  group_type: string;
  mismatch_confirmation_timestamp: string | null;
  amount_linked: number;
  linking_difference: number;
  cash_changed: number;
}

export interface CashListTransaction {
  id: string;
  status: string | null;
  type: string;
  amount: number;
  cash_list_id: string;
  group_id: string;
  employee_id: string;
  coin_change: number | null;
  bank_account_amount_change: number;
  notes: string | null;
  group_list: CashListTransactionGroupList;
}

export interface PliantCardholder {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  pliant_cardholder_id: string;
  email_verified: boolean | null;
}

export interface PliantOrganization {
  id: string;
  iban_last_4: string | null;
  mangopay_mandate_id: string | null;
  mangopay_user_id: string;
  pliant_organization_id: string;
  mangopay_wallet_id: string;
  base_data: any | null;
  mangopay_kyc_verified: boolean;
  mangopay_bank_account_id: string | null;
  mangopay_mandate_status: string | null;
  company_number: string;
  legal_person_type: string;
  legal_representative_base_data: any | null;
  mangopay_pliant_bank_account_id: string | null;
  payout_aggregation: string;
  payout_day_of_week: string | null;
  pliant_iban_last_4: string | null;
}

export interface PliantTransaction {
  transaction_country: TransactionCountry;
  cash_list_transactions: CashListTransaction[];
  billing_amount: number;
  pliant_status: PliantStatus;
  pliant_type:
    | 'PURCHASE'
    | 'CASH_WITHDRAWAL'
    | 'REFUND'
    | 'CHARGEBACK'
    | 'RECHARGE'
    | 'STATUS_INQUIRY';
  booked_at: string;
  pliant_transaction_id: string;
  pliant_card_account_id: string;
  pliant_card_id: string;
  pliant_category:
    | 'ADVERTISING_AND_MARKETING'
    | 'COMPUTING_AND_SOFTWARE'
    | 'EDUCATION_AND_TRAINING'
    | 'ELECTRONICS_AND_IT_EQUIPMENT'
    | 'ENTERTAINMENT_AND_WELLNESS'
    | 'FOOD_AND_DRINKS'
    | 'GIFTS_AND_VOUCHERS'
    | 'MATERIALS_AND_PACKAGING'
    | 'OFFICE_SUPPLIES_AND_EQUIPMENT'
    | 'SERVICES'
    | 'TRAVEL_AND_ACCOMMODATION'
    | 'OTHER';
  created_at: string;
  authorized_at: string;
  confirmed_at: string;
  reversed_at: string | null;
  pliant_merchant_id: string | null;
  merchant_name: string;
  mcc: string;
  merchant_terminal_id: string;
  inserted_at: string;
  mobile_wallet: string | null;
  updated_at: string;
  merchant_base_data: any | null;
  pliant_cardholder: PliantCardholder;
  pliant_organization: PliantOrganization;
}

export enum PliantStatus {
  DECLINED = 'DECLINED',
  PENDING = 'PENDING',
  REVERSED = 'REVERSED',
  CONFIRMED = 'CONFIRMED',
  BOOKED = 'BOOKED',
}

export interface GetPliantTransactionsParams {
  params: {
    page: number;
    page_size: number;
    legal_entity_id: string;
    nursing_home_id?: string;
    mismatch?: boolean;
    has_cash_list_transaction?: boolean;
    employee_ids?: string[];
    pliant_status?: PliantStatus[];
  };
}

export interface GetPliantTransactionsResponse {
  data: PliantTransaction[];
  meta: {
    total_count: number;
    total_pages: number;
    current_page: number;
    has_next_page: boolean;
    has_previous_page: boolean;
    page_size: number;
  };
}

export interface IPliantPhoenixApi extends Api {
  getPliantTransactions(
    params: GetPliantTransactionsParams
  ): Promise<GetPliantTransactionsResponse>;
}

export class PliantPhoenixApi implements IPliantPhoenixApi {
  baseUrl: string;

  getPliantTransactions: IPliantPhoenixApi['getPliantTransactions'];

  constructor(
    tenantId: Tenant,
    public authContext: AuthenticationContext,
    apiUrl?: string
  ) {
    this.baseUrl = getPhoenixApiTenantUrl(tenantId, apiUrl);

    this.getPliantTransactions = get({
      authContext,
      url: (params) => {
        const query = new URLSearchParams();

        query.append('page', params.page.toString());
        query.append('page_size', params.page_size.toString());

        let i = 0;
        if (params?.has_cash_list_transaction !== undefined) {
          query.append(`filters[${i}][field]`, 'cash_list_transaction_id');
          query.append(`filters[${i}][op]`, 'not_empty');
          query.append(
            `filters[${i}][value]`,
            params.has_cash_list_transaction ? 'true' : 'false'
          );
          i++;
        }

        if (params?.nursing_home_id !== undefined) {
          query.append('nursing_home_id', params.nursing_home_id);
        }

        if (params?.employee_ids !== undefined) {
          query.append(`filters[${i}][field]`, 'employee_id');
          query.append(`filters[${i}][op]`, 'in');

          for (const employeeId of params.employee_ids) {
            query.append(`filters[${i}][value][]`, employeeId);
          }
          i++;
        }

        if (params?.pliant_status !== undefined) {
          query.append(`filters[${i}][field]`, 'pliant_status');
          query.append(`filters[${i}][op]`, 'in');

          for (const status of params.pliant_status) {
            query.append(`filters[${i}][value][]`, status);
          }
          i++;
        }

        query.append('order_by[]', 'created_at');
        query.append('order_directions[]', 'desc');

        if (params?.mismatch) {
          query.append(`mismatch`, 'true');
        }

        return `${this.baseUrl}/legal-entity/${
          params.legal_entity_id
        }/pliant-transactions?${query.toString()}`;
      },
    });
  }
}
